import '../styles/Recipe.css';
import { useState, useEffect } from "react"
import firebase from "firebase/app";
import { useHistory } from 'react-router-dom';
require('firebase/database');


const GamePlayerAdd = () => {
  const [player, setPlayer] = useState(null)
  const [loading, setLoading] = useState(null)
  const [players, setPlayers] = useState(null)
  const takenNames = Object.keys(players || [])

  const historyy = useHistory()

  useEffect(() => {
    firebase.database().ref().child("games/1/players").on('value', (snapshot) => {
      const value = snapshot.val();
      setPlayers(value)
    });
  }, [])
  

  const handleClick = () => {
    if (!player || takenNames.includes(player) || loading) return
    setLoading(true)
    let updates = {}
    updates["games/1/players/" + player.toLowerCase()] = player

    firebase.database().ref().update(updates).then(() => {
      historyy.push("game-player/" + player.toLowerCase())
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <div style={{ height: "100vh", display: "grid", gridTemplateRows: "10% 90%" }}>
      <input style={{  fontSize: "2rem" }} onChange={(e) => setPlayer(e.target.value)}  placeholder="Jméno" />
      <button style={{  fontSize: "3rem" }} disabled={!player || takenNames.includes(player) || loading} onClick={() => handleClick()}>HRÁT!</button>
    </div>  
  );
}
  
export default GamePlayerAdd;
