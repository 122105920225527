import '../styles/Recipe.css';
import { useState, useEffect } from "react"
import firebase from "firebase/app";
import AuthDialog from '../components/AuthDialog';

require('firebase/database');


const Game = (props) => {
  const id = props?.match?.params?.id
  const [activePlayerId, setActvivePlayerId] = useState('')
  const [teamAPoints, setTeamAPoints] = useState(0)
  const [teamBPoints, setTeamBPoints] = useState(0)
  const [page, setPage] = useState(1)
  const [logedIn, setLogedIn] = useState(false)

  const onLogin = () => {
    setLogedIn(true)
  }
  

  useEffect(() => {
    firebase.database().ref().child(`games/${id}/active_player`).on('value', (snapshot) => {
      const value = snapshot.val();
      setActvivePlayerId(value)
    });

    firebase.database().ref().child(`games/${id}/team_a_points`).on('value', (snapshot) => {
      const value = snapshot.val();
      setTeamAPoints(value)
    });

    firebase.database().ref().child(`games/${id}/team_b_points`).on('value', (snapshot) => {
      const value = snapshot.val();
      setTeamBPoints(value)
    });
  }, [])


  if (!id) {
    return (
      <div>Načítám...</div>
    )
  }

  if (!logedIn) {
    return <AuthDialog onLogin={onLogin}/>
  }

  if (page === 1) {
    return (
      <div style={{ display: "grid", gridTemplateRows: "20vh 80vh" }}>
        <div style={{ display: "flex", }}>
          <div style={{ display: "grid", gridTemplateRows: "1fr 1fr", fontSize: "1.5rem" }}>
            <p>Body týmu a: {teamAPoints}</p>
            <p>Body týmu b: {teamBPoints}</p>
          </div>
          <button style={{ margin: "auto", marginRight: "5vw", fontSize: "2rem" }} onClick={() => setPage(2)}>Zdroje</button>
        </div>
        <div style={activePlayerId ? { display: "flex", backgroundColor: "green" } : { display: "flex" }} >
          <p style={{ fontSize: "10rem", margin: "auto" }}>{activePlayerId}</p>
        </div>
      </div>  
    )
  }

  return (
    <div style={{ display: "grid", gridTemplateRows: "20vh 80vh" }}>
      <div style={{ display: "flex", }}>
        <div style={{ display: "grid", gridTemplateRows: "1fr 1fr", fontSize: "1.5rem" }}>
          <p>Body týmu a: {teamAPoints}</p>
          <p>Body týmu b: {teamBPoints}</p>
        </div>
        <button style={{ margin: "auto", marginRight: "5vw", fontSize: "2rem" }} onClick={() => setPage(1)}>Přehled</button>
      </div>
      <div >
        <div style={{ display: "flex", gap: "3rem" }}>
          <a href="https://photos.app.goo.gl/1YjcXaRdH34iJDyL9" target="_blank">Napoveda 1</a>
          <a href="https://youtu.be/YSn6R-JwY3I?si=_LWxojWpzZOmIKvv&t=5" target="_blank">Video 1</a>
        </div>
        <div>
          <a href="https://youtu.be/7r2LIrCY36k?si=zBJJ6CLAoHdOOOuH&t=21" target="_blank">Video 2</a>
        </div>
        <div>
          <a href="https://youtu.be/fQklj6o4OqQ?si=VA-cBNWJprGPRHum" target="_blank">Filmová ukázka 1</a>
        </div>
        <div>
          <a href="https://youtu.be/CgCN6XhAVXk?si=2BZCluKFplqt6Jtb" target="_blank">Filmová ukázka 2</a>
        </div>
        <div>
          <a href="https://youtu.be/GTAhQ05mODg?si=C6-jEgVw3dsGoZv-" target="_blank">Filmová ukázka 3</a>
        </div>
        <div>
          <a href="https://youtu.be/C_uQxQ_IzzE?si=WD-i_v2clxJNabDv" target="_blank">Filmová ukázka 4</a>
        </div>

        <div style={{ display: "flex", gap: "3rem" }}>
          <a href="https://photos.app.goo.gl/7A87krfKadDcfucD6" target="_blank">Napoveda</a>
          <a href="https://youtu.be/p3y4XQ4B4Vw?si=4yCzezetzf9hny9L&t=40" target="_blank">Video 3</a>
        </div>

        <div>
          <a href="https://onlinealarmkur.com/timer/cs/#90" target="_blank">Časovač</a>
        </div>
        <div>
          <a href="https://cs.piliapp.com/random/wheel/" target="_blank">Kolo štěstí</a>
        </div>
      </div>
    </div>  
  )
}


export default Game;
