import '../styles/Recipe.css';
import { useState, useEffect } from "react"
import firebase from "firebase/app";

require('firebase/database');


const GamePlayer = (props) => {
  const id = props?.match?.params?.id
  const [player, setPlayer] = useState(null)
  const [activePlayerId, setActvivePlayerId] = useState('')
  const [locked, setLocked] = useState(false)

  useEffect(() => {
    firebase.database().ref().child("games/1/players/" + id).on("value", p => {
      if (!p.val && !player) {
        return
      }
      setPlayer(p.val())
    })

    firebase.database().ref().child("games/1/active_player").on('value', (snapshot) => {
      const value = snapshot.val();
      setActvivePlayerId(value)
    });

    firebase.database().ref().child("games/1/locked").on('value', (snapshot) => {
      const value = snapshot.val();
      setLocked(value)
    });
  }, [])

  const handleClick = () => {
    if (locked) return
    let updates = {}
    updates["games/1/active_player"] = player
    updates["games/1/locked"] = true

    firebase.database().ref().update(updates)
  }

  if (!player) {
    return (
      <div>Načítám...</div>
    )
  }

  return (
    <div style={activePlayerId === player ? { height: "100vh", display: "grid", gridTemplateRows: "10% 90%", backgroundColor: "green" } : { height: "100vh", display: "grid", gridTemplateRows: "10% 90%" }}>
      <p style={{ padding: 0, margin: "auto", fontSize: "2rem" }}>{player}</p>
      <button style={{  fontSize: "3rem" }} disabled={locked} onClick={() => handleClick()}>JÁ VÍM!</button>
    </div>  
  );
}
  
export default GamePlayer;
