import AuthDialog from "../components/AuthDialog"
import { useState, useEffect } from "react"
import firebase from "firebase/app";
require('firebase/database');

const GameAdmin = () => {
  const [logedIn, setLogedIn] = useState(false)
  const [points, setPoints] = useState(0)
  const [teamAPoints, setTeamAPoints] = useState(0)
  const [teamBPoints, setTeamBPoints] = useState(0)

  const onLogin = () => {
    setLogedIn(true)
  }
  

  useEffect(() => {
    firebase.database().ref().child(`games/1/team_a_points`).on('value', (snapshot) => {
      const value = snapshot.val();
      setTeamAPoints(value)
    });

    firebase.database().ref().child(`games/1/team_b_points`).on('value', (snapshot) => {
      const value = snapshot.val();
      setTeamBPoints(value)
    });
  }, [])

  const handleUnlock = () => {
    let updates = {}
    updates["games/1/active_player"] = ""
    updates["games/1/locked"] = false

    firebase.database().ref().update(updates)
  }

  const handleAddPointsA = () => {
    let updates = {}
    updates["games/1/team_a_points"] = teamAPoints + points

    firebase.database().ref().update(updates)
  }

  const handleAddPointsB = () => {
    let updates = {}
    updates["games/1/team_b_points"] = teamBPoints + points

    firebase.database().ref().update(updates)
  }

  return (
    <div>
      {!logedIn && (
        <AuthDialog onLogin={onLogin}/>
      )}
      {logedIn && (
        <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
          <button style={{ margin: "auto", fontSize: "4rem" }} onClick={handleUnlock}>Reset</button>
          <input style={{ height: "2rem" }}  type="number" onChange={(e) => setPoints(Number(e.target.value))} />
          <button style={{ margin: "auto", fontSize: "2rem" }} onClick={handleAddPointsA}>Body týmu A</button>
          <button style={{ margin: "auto", fontSize: "2rem" }} onClick={handleAddPointsB}>Body týmu B</button>
        </div>
      )}
    </div>
  );
}
  
export default GameAdmin;
