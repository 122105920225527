import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import RecipesList from "./pages/RecipesList";
import Recipe from "./pages/Recipe";
import Admin from "./pages/Admin";
import { Provider } from 'react-redux';
import firebase from "firebase/app";
import store from './redux/store';
import GameAdmin from './pages/GameAdmin';
import GamePlayer from './pages/GamePlayer';
import Game from './pages/Game';
import GamePlayerAdd from './pages/GamePlayerAdd';

require('firebase/database');

const firebaseConfig = {
  apiKey: "AIzaSyC9AzygC5RLB6mYOo4VVixzN3k5PgwsmkE",
  authDomain: "nasedobroty-8e34d.firebaseapp.com",
  databaseURL: "https://nasedobroty-8e34d-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "nasedobroty-8e34d",
  storageBucket: "nasedobroty-8e34d.appspot.com",
  messagingSenderId: "652727793024",
  appId: "1:652727793024:web:721ff4422a07b78af5fd65",
  measurementId: "G-NJJJMMVEZ8"
};

function App() {
  const isGamePage = window.location.href.includes("game");

  if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig)
  } else {
      firebase.app(); // if already initialized, use that one
  }
  return (
    <div className={isGamePage ? "" : "App"}>
      <div className={isGamePage ? "" : "Board"}>
        <Provider store={store}>
          <Router>
            <Switch>
              <Route exact path="/" component={RecipesList} />
              <Route path="/recipe/:id" component={Recipe} />
              <Route path="/admin" component={Admin} />
              <Route path="/game-admin" component={GameAdmin} />
              <Route path="/game-player-admin" component={GameAdmin} />
              <Route path="/game-player/:id" component={GamePlayer} />
              <Route path="/game-player-add" component={GamePlayerAdd} />
              <Route path="/game/:id" component={Game} />
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </Router>
        </Provider>
      </div>
    </div>
  );
}

export default App;
