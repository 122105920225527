import '../styles/Admin.css';
import { useState, useEffect } from "react"
import Axios from "axios"
import CustomCheckBox from "../components/CustomCheckBox"
import firebase from "firebase/app";
import { tagsToRender } from "../utils/constants"

require('firebase/database');

const AddRecipe = () => {
    const [recipeName, setRecipeName] = useState("")
    const [currentIngredientAmmount, setCurrentIngredientAmmount] = useState("")
    const [currentIngredientName, setCurrentIngredientName] = useState("")
    const [ingredients, setIngredients] = useState([])
    const [currentStep, setCurrentStep] = useState([])
    const [steps, setSteps] = useState([])
    const [foto, setFoto] = useState()
    const [tags, setTags] = useState([])
    const [addingRecipe, setAddingRecipe] = useState(false)
    const [recipeUploaded, setRecipeUploaded] = useState(false)
    const [errorMesage, setErrorMessage] = useState("")

    // Dispaly message for 4 seconds
    useEffect(() => {
        if (recipeUploaded) {
            setTimeout(() => {
                setRecipeUploaded(false)
            }, 4000)
        }
    }, [recipeUploaded])

    const resetValues = () => {
        setRecipeName("")
        setIngredients([])
        setSteps([])
        setFoto(null)
        setTags([])
    }

    const addRecipe = (e) => {
        //Validation
        setErrorMessage("")
        if (!recipeName) {
            setErrorMessage("Je potřeba zadat název receptu.")
            return
        }
        if (!ingredients || ingredients.length === 0) {
            setErrorMessage("Je potřeba přidat alespoň jednu ingredienci.")
            return
        }
        if (!steps || steps.length === 0) {
            setErrorMessage("Je potřeba přidat alepsoň jedne krok.")
            return
        }
        if (!foto) {
            setErrorMessage("Je potřeba nahrát fotku receptu.")
            return
        }

        // Flag
        setAddingRecipe(true)

        // Prepare recipe object
        const recipe = {
            name: recipeName,
            ingredients,
            procedure: steps.map((item) => item.step),
            tags,
        }

        // Prepare foto
        const formDataForImage = new FormData()
        formDataForImage.append("file", foto)
        formDataForImage.append("upload_preset", "naseDobrotyAdmin")

        // Upload foto
        Axios.post(
            "https://api.cloudinary.com/v1_1/dvd0muryl/image/upload",
            formDataForImage
        ).then((response) => {
            // Set thumb and uplaod recipe to firebase
            recipe.thumb = response.data.public_id
            let newPostKey = firebase.database().ref().child('recipes').push().key
            recipe.id = newPostKey
            let updates = {}
            updates['/recipes/' + newPostKey] = recipe

            firebase.database().ref().update(updates)
            setAddingRecipe(false)
            setRecipeUploaded(true)
            resetValues()
        }).catch(() => {
            setAddingRecipe(false)
            resetValues()
            setErrorMessage("RECEPT SE NEPODAŘILO NAHRÁT")
        })
    }

    const addIngredient = () => {
        if (!currentIngredientName) {
            return
        }
        const newIngredient = {
            name: currentIngredientName,
            amount: currentIngredientAmmount,
            id: Date.now()
        }
        setIngredients([...ingredients, newIngredient])
        setCurrentIngredientAmmount("")
        setCurrentIngredientName("")
    }

    const removeIng = (id) => {
        const newIngredients = ingredients.filter((ing) => ing.id !== id)
        setIngredients(newIngredients)
    }

    const addStep = () => {
        if (!currentStep) {
            return
        }
        const newStep = {
            step: currentStep,
            id: Date.now()
        }
        setSteps([...steps, newStep])
        setCurrentStep("")
    }

    const removeStep = (id) => {
        const newSteps = steps.filter((step) => step.id !== id)
        setSteps(newSteps)
    }

    const moveStepUp = (id) => {
        const stepsCopy = steps.slice()
        const selectedStep = stepsCopy.find((step) => step.id === id)
        const oldIndex = stepsCopy.indexOf(selectedStep)
        if (oldIndex === 0) {
            return
        }
        const newIndex = oldIndex - 1
        stepsCopy.splice(newIndex, 0, stepsCopy.splice(oldIndex, 1)[0])
        setSteps(stepsCopy)
    }

    const moveStepDown = (id) => {
        const stepsCopy = steps.slice()
        const selectedStep = stepsCopy.find((step) => step.id === id)
        const oldIndex = stepsCopy.indexOf(selectedStep)
        if (oldIndex === stepsCopy.length - 1) {
            return
        }
        const newIndex = oldIndex + 1
        stepsCopy.splice(newIndex, 0, stepsCopy.splice(oldIndex, 1)[0])
        setSteps(stepsCopy)
    }

    const checkBoxChange = (value, add) => {
        if (add) {
            setTags([...tags, value])
        } else {
            const newTags = tags.filter((tag) => tag !== value)
            setTags(newTags)
        }
    }

    if (addingRecipe) {
        return (
            <div className="AdminBlock">
                <h1>Nahrávám recept...</h1>
            </div>
        )
    }

    if (recipeUploaded) {
        return (
            <div className="AdminBlock">
                <h1>Recept byl úspěšně nahrán!</h1>
            </div>
        )
    }

    return (
        <div className="AdminContainer">
            {errorMesage && (
                <div className="AdminBlock">
                    <h2 className="Error">{errorMesage}</h2>
                </div>
            )}
            <div className="AdminBlock">
                <label><h2 className="AdminHeader">Název receptu</h2></label>
                <input className="NameInput" type="text" onChange={(e) => {
                    setRecipeName(e.target.value)
                }}/>
            </div>
            <div className="AdminBlock">
                <label><h2 className="AdminHeader">Foto Receptu</h2></label>
                <input type="file" onChange={(e) => {
                    setFoto(e.target.files[0])
                }}/>
            </div>
            <div className="AdminBlock">
                <label><h2 className="AdminHeader">Ingredience</h2></label>
                <div className="InputParts">
                    <div className="InputPartBox">
                        <label>Množství</label>
                        <input id="IngredientAmmount" className="InputPartInput" value={currentIngredientAmmount || ""} type="text" onChange={(e) => {
                            setCurrentIngredientAmmount(e.target.value)
                        }} onKeyDown={(event) => {
                            if (event.code === 'Enter') {
                                document.getElementById("IngredienName").focus()
                            }
                        }}/>
                    </div>
                    <div className="InputPartBox">
                        <label>Název</label>
                        <input id="IngredienName" className="InputPartInput" value={currentIngredientName ||""} type="text" onChange={(e) => {
                            setCurrentIngredientName(e.target.value)
                        }} onKeyDown={(event) => {
                            if (event.code === 'Enter') {
                                addIngredient()
                                document.getElementById("IngredientAmmount").focus()
                            }
                        }}/>
                    </div>
                    <button className="AddButton" onClick={addIngredient}>Přidat</button>
                </div>
            </div>
            <ul>
                {ingredients.map((ing) => {
                    return (
                        <li>
                            <div>
                                {ing.amount} {ing.name}
                                <button onClick={() => removeIng(ing.id)} className="DeleteButton">Del.</button
                            ></div>
                        </li>
                    )
                })}
            </ul>
            <div className="AdminBlock">
                <label><h2 className="AdminHeader">Postup</h2></label>
                <div className="InputParts">
                    <div className="StepBox">
                        <label>Krok</label>
                        <input className="StepInput" value={currentStep || ""} type="text" onChange={(e) => {
                            setCurrentStep(e.target.value)
                        }} onKeyDown={(event) => {
                            if (event.code === 'Enter') {
                                addStep()
                            }
                        }}/>
                    </div>
                    <button className="AddButton" onClick={addStep}>Přidat</button>
                </div>
            </div>
            <ul>
                {steps.map((step) => {
                    return (
                        <li>
                            <div>
                                {step.step}
                                <button onClick={() => removeStep(step.id)} className="DeleteButton">Del.</button>
                                <button onClick={() => moveStepUp(step.id)} className="MoveButton">↑</button>
                                <button onClick={() => moveStepDown(step.id)} className="MoveButton">↓</button>
                            </div>
                        </li>
                    )
                })}
            </ul>
            <div className="AdminBlock">
                <label><h2 className="AdminHeader">Kategorie</h2></label>
                {tagsToRender.map((tag) => (
                    <CustomCheckBox
                        label={tag}
                        onChange={checkBoxChange}
                        key={tag}
                        checked={tags.includes(tag)}
                    />
                ))}
            </div>
            {errorMesage && (
                <div className="AdminBlock">
                    <h2 className="Error">{errorMesage}</h2>
                </div>
            )}
            <div className="AdminBlock">
                <button className="SubmitButton" onClick={addRecipe}>Přidat Recept</button>
            </div>
        </div>
    );
}
  
export default AddRecipe;
